$base: #f3f5f8;
$darkbase: #dadcde;
$darkgray: #444b55;
$lightgray: #f0f2f5;

// disco-ui
$primary: #00b481;
$warning: #f5c022;
$danger: #cf5151;
$success: #19bf82;
$info: #679fd9;

// RDN specific
$purple: #7700e9;

// shadow
$shadow1: 0 0.5px 1.5px rgba(0, 0, 0, 0.045), 0 0.5px 1px rgba(0, 0, 0, 0.09);
$shadow2: 0 1.5px 3px rgba(0, 0, 0, 0.065), 0 1.5px 3px rgba(0, 0, 0, 0.085);
$shadow3: 0 5px 10px rgba(0, 0, 0, 0.08), 0 3px 3px rgba(0, 0, 0, 0.085);
$shadow4: 0 7px 14px rgba(0, 0, 0, 0.11), 0 5px 5px rgba(0, 0, 0, 0.075);
$shadow5: 0 9.5px 19px rgba(0, 0, 0, 0.135), 0 7.5px 6px rgba(0, 0, 0, 0.075);

// easing
$easing: cubic-bezier(0.25, 0.8, 0.25, 1);
