$danger: #e31059;

.sku-underlinetextinput {
  opacity: 0.8;

  &--wrapper {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      display: block;
      width: 100%;
      height: 1px;
      background: #212b36;
    }
  }

  &--input {
    background: transparent;

    border: none;
    outline: none;
    line-height: 1em;
    display: block;
    width: 100%;
    padding: 0.5em 0;
    font-size: 15px;
    color: #212b36;
    caret-color: #212b36;

    &::placeholder {
      font-size: 15px;
      color: #212b36;
    }
  }

  &.focused {
    opacity: 1;
  }

  &.danger {
    .sku-UnderlineTextInput--wrapper::after {
      background: $danger;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    font-size: 15px;
    transition: background-color 5000s;
    -webkit-text-fill-color: #212b36 !important;
  }
}
