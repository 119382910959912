@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    background: #f0f2f5;
  }
  body {
    color: #333;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    @apply font-medium;
  }

  h1 {
    @apply text-2xl;
  }

  h2 {
    @apply text-xl;
  }

  h3 {
    @apply text-lg;
  }

  h4 {
    @apply text-base;
  }

  h5 {
    @apply text-sm;
  }

  hr {
    @apply border border-gray-100 opacity-60;
  }
}

@layer utilities {
  .text-link {
    @apply text-light-info relative inline-block cursor-pointer hover:underline focus:underline;
  }
}

.TextInput {
  @apply text-black;
}

.TextInput.medium .TextInput--append,
.TextInput.medium .TextInput--prepend {
  @apply top-auto;
}

.Button {
  @apply focus:outline-none;
}

.Tooltip {
  @apply block;
}

.TextArea {
  @apply text-black;
}
