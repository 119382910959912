.sku-toggle {
  @mixin checked-bg-img($fill) {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='#{$fill}' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
  }

  &.disabled {
    @apply opacity-30;
  }

  input[type='checkbox'] {
    @apply appearance-none shrink-0 w-4 h-4 rounded border-2 outline-none;

    &:hover {
      @apply ring-4;

      &:disabled,
      &.readOnly {
        @apply ring-0;
      }
    }

    &:checked {
      @include checked-bg-img(black);
    }
  }

  &.inverted input[type='checkbox']:checked {
    @include checked-bg-img(white);
  }

  input[type='radio'] {
    @apply appearance-none flex-shrink-0 w-4 h-4 rounded-full border-2 outline-none inline-flex justify-center items-center;

    &:after {
      content: '';
      @apply w-2 h-2 block border rounded-full;
    }

    &:hover {
      @apply ring-4;

      &:disabled,
      &.readOnly {
        @apply ring-0;
      }
    }
  }

  @mixin theme($color) {
    &.#{$color} {
      input[type='checkbox'] {
        @apply ring-#{$color}-50;

        &:hover {
          @apply border-#{$color}-500;

          &:after {
            @apply border-#{$color}-500;
          }

          &:disabled,
          &.readOnly {
            border-color: inherit;

            &:after {
              border-color: inherit;
            }
          }
        }
      }

      input[type='radio'] {
        @apply ring-#{$color}-50;

        &:hover {
          @apply border-#{$color}-500;

          &:after {
            @apply border-#{$color}-500;
          }

          &:disabled,
          &.readOnly {
            border-color: inherit;

            &:after {
              border-color: inherit;
            }
          }
        }

        &:checked {
          @apply border-#{$color}-500;

          &:after {
            @apply bg-#{$color}-500 border-transparent;
          }
        }
      }
    }
  }
  @include theme('green');
  @include theme('aqua');
  @include theme('berry');
  @include theme('orange');
}
