$easing: cubic-bezier(0.25, 0.8, 0.25, 1);

.sku-modal {
  animation: Modal-enter 0.2s $easing;
  @apply outline-none;

  &--shade {
    animation: ModalShade-enter 0.2s $easing;
    z-index: 9999;
    @apply fixed left-0 right-0 top-0 bottom-0 bg-gray-500 bg-opacity-40 pt-10 md:pt-24 flex items-start justify-center;
  }
}
@keyframes ModalShade-enter {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes Modal-enter {
  from {
    opacity: 0;
    transform: scale(1.1);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
